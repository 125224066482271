import Vue from 'vue';
import VueRouter from 'vue-router';

import { beforeAdminRouteEnter, beforeIdentificationRouteEnter } from '@/assets/js/routerUtils';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'main',
    component: () => import('@/views/MainView'),
    children: [
      {
        path: 'identification',
        name: 'identification',
        components: {
          sidebar: () => import('@/components/main/sidebar/IdentificationHandler'),
        },
      },
      {
        path: 'identification/:lid/:fid',
        name: 'layerFeature',
        components: {
          sidebar: () => import('@/components/main/sidebar/FeatureInfo'),
        },
        beforeEnter: (to, from, next) => {
          beforeIdentificationRouteEnter(to, from, next, () => next());
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminView'),
    beforeEnter: (to, from, next) => {
      beforeAdminRouteEnter(to, from, next, () => next());
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
