import axios from 'axios';
import store from '@/store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 0,
  headers: {},
});

instance.interceptors.request.use(config => {
  const { data, noWrap } = config;
  if (!noWrap) {
    if (data) {
      config.data = { data };
    }
  }
  const { token } = store.state.authentication;
  if (token) {
    config.headers['X-Access-Token'] = token;
  }
  if (config.url.includes('aquagis3-dev')) {
    config.headers['X-Response-SRID'] = 3857;
  }
  return config;
});

instance.interceptors.response.use(config => {
  return config;
});

export default instance;
