import api from '@/services/api';

const actions = {
  async searchAddressByGugik(store, payload) {
    const { query, prefix } = payload;
    const address = `${prefix}${query}`;
    const res = await fetch(
      `https://services.gugik.gov.pl/uug/?srid=3857&request=GetAddress&` +
        new URLSearchParams({
          address: address,
        })
    ).then(response => response.json());
    const results = res.results;
    const mappedResults = results
      ? Object.values(results).map(v => {
          return {
            ...v,
            geometry: {
              coordinates: [parseFloat(v.x), parseFloat(v.y)],
              crs: {
                properties: {
                  name: 'EPSG:3857',
                },
                type: 'name',
              },
              type: 'Point',
            },
            description: prefix
              ? `${v.street ? `${v.street}` : ''}${v.number ? ` ${v.number}` : ''}`
              : `${v.city}${v.street ? `, ${v.street}` : ''}${v.number ? ` ${v.number}` : ''}`,
          };
        })
      : null;
    return results === null ? [] : mappedResults;
  },
  async searchAddressBySystem(store, params) {
    return (await api.get(`addresses/search`, { params })).data.data;
  },
};

export default {
  namespaced: true,
  actions,
};
