import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1a73e8',
        error: '#bf360c',
        link: '#045daf',
      },
    },
  },
  lang: {
    current: 'pl',
  },
});
