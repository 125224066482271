import Vue from 'vue';
import { make } from 'vuex-pathify';
import api from '@/services/api';

const state = {
  activeTool: '',
  coordinatesEpsgs: {
    'EPSG:3857': {
      text: 'EPSG:3857',
      srid: 3857,
    },
    'EPSG:4326': {
      text: 'EPSG:4326 (GPS)',
      srid: 4326,
    },
  },
  currentFeature: undefined,
  cursorPositionEpsg: 'EPSG:3857',
  highlitedFeatures: {},
  identifiedFeatures: {},
  isOpenStreetMapVisible: true,
  layersToBeChanged: new Set(),
  scale: undefined,
  selectedFeatures: {},
  sidebarTab: 0,
  project: undefined,
};

const mutations = {
  ...make.mutations(state),
  ADD_SELECTED_FEATURES: (state, value) => {
    const { features, type } = value;
    const changedLayers = Object.keys(features).map(layerId => {
      Vue.set(state[type], layerId, Array.from(new Set([...(state[type][layerId] || []), ...features[layerId]])));
      return layerId;
    });
    state.layersToBeChanged = new Set([...state.layersToBeChanged, ...changedLayers]);
  },
  DELETE_SELECTED_FEATURES: (state, value) => {
    const { features, type } = value;
    const changedLayers = Object.keys(features)
      .filter(layerId => state[type][layerId])
      .map(layerId => {
        if (features[layerId].length === 0) {
          Vue.delete(state[type], layerId);
        } else {
          features[layerId].forEach(layer => {
            Vue.delete(state[type][layerId], state[type][layerId].indexOf(layer));
          });
        }
        return layerId;
      });
    state.layersToBeChanged = new Set([...state.layersToBeChanged, ...changedLayers]);
  },
};

const actions = {
  async getTile(store, payload) {
    const { layerId, envelope, features_filter, attributes } = payload;
    return await api.post(
      `${process.env.VUE_APP_MAIN_API_URL}/layers/features_layers/${layerId}/mvt`,
      { features_filter, envelope, attributes },
      { responseType: 'arraybuffer' }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
